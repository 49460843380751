<template>
  <flight-map :operator-items="['map-mode', 'undo', 'clear', 'gps']"
              :load-items="['no_fly_zone']"
              @operator="o => $emit('operator', o)"
              @map-click="mapClick"
              ref="map"
  >
    <polygon-editor :polygonComponent="FlyArea"
                    :zone="flyZone"
                    zoneType="fence"
                    @update:zone="updateFence"
                    @before-input="saveHistory"
                    :z-index="30"
    />
    <multiple-point :value="elzData"
                    type='elz'
                    @input="elzUpdate"
                    @before-input="saveHistory"
                    :z-index="31"
    />
  </flight-map>
</template>

<script>
  import { CopyObject } from '@/utils/common.js';
  import { GeographicShorter, GeographicLonger, FormatPolygonsToLatitudeLongitude } from '@/utils/map.js';
  import { GeographicApiToOl, GeographicOlToApi, BoundsOlToApi, PolygonsApiToOl } from '@/utils/map.js';
  import { Icon as MarkerIcon } from '@/components/GoogleMaps/MarkerStyle.js';
  import { formatNoFlyZoneTypeToOlComponent } from '@/utils/map.js';
  import {logger} from "@/logger/index";
  import MultiplePoint from '@/components/OpenLayers/MultiplePoint.vue';
  import PolygonEditor from '@/components/OpenLayers/PolygonEditor.vue';
  import FlyArea from '@/components/OpenLayers/FlyArea.vue';
  import FlightMap from '@/components/OpenLayers/FlightMap.vue';
  // import {Icon } from '@/components/GoogleMaps/MarkerStyle.js';
  // import Nest from '@/components/GoogleMaps/Nest.js';

  import { NoFlyZoneVM } from '@/models/noFlyZone';

  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'SettingLocationMapOpenLayers',
    components: {
      FlightMap,
      PolygonEditor,
      // Nest,
      MultiplePoint,
    },
    data() {
      return {
        flyZone: {
          id: 'fence',
          name: 'fence',
          coordinates: [],
        },
        elzData: {
          id: 'elz',
          name: 'elz',
          list: [],
        },
        mapEditType: 'fence',
        // mapEditZoneIndex: null,
        zoneDialogDataIndex: null,
        history: [],
        historyPosition: 0,
        FlyArea,
        confirmShow: false,
        beforeConfirmShow : false,
        selectedPoint: null,
      };
    },
    computed: {
      ...mapGetters({
        group: 'user/group',
        groupEditData: 'group/getData',
        groupEditShow: 'group/shouldShow',
        groupEditMode: 'group/getMode',
      }),
    },
    watch: {
      groupEditData() {
        this.loadFromVuex();
      },
    },
    async mounted() {
      this.loadFromVuex();
    },
    methods: {
      mapClick(event, featureAndLayerList) {
        let clickEvent = {
          position: GeographicOlToApi(event.coordinate),
        };

        // Todo: check click on elz

        let onFenceMiddleFeature = featureAndLayerList.find(featureAndLayer => {
          return featureAndLayer.properties.type === 'middlePoint'
            && featureAndLayer.properties.source === 'fence';
        })
        if (onFenceMiddleFeature) {
          clickEvent.source = 'fence-middle'
          clickEvent.sourceId = onFenceMiddleFeature.properties.sequence;
          this.$emit('map-click', clickEvent);
          return;
        }

        let onFenceFeature = featureAndLayerList.find(featureAndLayer => {
          return featureAndLayer.properties.type === 'polygon'
            && featureAndLayer.properties.source === 'fence';
        })
        if (onFenceFeature) {
          clickEvent.source = 'fence'
          this.$emit('map-click', clickEvent)
          return;
        }
        
        this.$emit('map-click', clickEvent)
      },
      saveHistory() {
        this.$emit('before-data-update');
      },
      updateFence(zone) {
        let fence = zone.coordinates[0].map(GeographicOlToApi);
        fence[0] = fence.pop();

        let data = CopyObject(this.groupEditData);
        data.fence = fence;
        this.setGroupEditData(data);
      },
      elzUpdate(data) {
        let elzInFence = data.list.map((elzItem) => {
          let features = this.$root.$ol.findFeatureOnCoordinate(elzItem.coordinate);

          let fence = features.find(feature => {
            return feature.properties.source === 'fence';
          });
          return Boolean(fence);
        });
        if (elzInFence.indexOf(false) >=0 ) {
          this.$emit('operator', 'undo');
          return;
        }

        let newElzs = data.list.map((elzItem, index) => {
          delete elzItem.icon;
          delete elzItem.iconSet;
          let position = GeographicOlToApi(elzItem.coordinate);
          
          return {
            ...position,
            ...elzItem,
          };
        });

        let newGroupData = CopyObject(this.groupEditData);
        newGroupData.elz = newElzs;
        this.setGroupEditData(newGroupData);
        logger.debug(data);
        logger.debug(newElzs)
      },
      formatNoFlyZoneTypeToOlComponent,
      ...mapActions({
        setGroupEditData : 'group/setData',
        setGroupEditMode : 'group/setEditMode',
        exportGroupData : 'group/exportData',
        updateGroupData: 'user/getGroups',
      }),
      // elzDelete(index) {
      //   logger.debug('elzDelete:'+index);
      //   this.map.elz.splice(index,1);
      // },
      // deletePoint(point){
      //   this.map.fence.splice(point,1);
      //   logger.debug(point);
      // },
      // checkPositionInZone(position, polygon) {
      //   return google.maps.geometry.poly.containsLocation(position, polygon.$polygonObject);
      // },
      validateConfirm(){
        if(this.map.fence.length===0 || this.map.elz.length===0)
          this.beforeConfirmShow=true;
        else
          this.confirmShow=true;
      },
      loadFromVuex() {
        logger.debug('load data from vuex');
        let fenceCoordinates = this.groupEditData.fence.map(GeographicApiToOl);
        if (fenceCoordinates.length > 0) {
          fenceCoordinates.push(fenceCoordinates[0]);
        }

        this.flyZone = {
          id: this.groupEditData.id,
          name: this.groupEditData.name,
          coordinates: [fenceCoordinates],
        };

        if (this.groupEditData.elz == null) {
          return;
        }
        this.elzData = {
          id: 'elz',
          name: 'elz',
          list: this.groupEditData.elz.map(elz => {
            let elzData = { ...elz };
            delete elzData.latitude;
            delete elzData.longitude;
            return {
              icon: 'elz',
              iconSet: MarkerIcon,
              ...elzData,
              coordinate: GeographicApiToOl(elz),
            }
          })
        };
      },
    },
    props: {
      noFlyZone: {
        type: Array,
      },
      mapRange: {
        type: Array,
      },
      typeCount: {
        type: Number,
      },
      editType: {
        type: String,
      },
      flyAreaClick: {
        required: false,
        type: Function,
        default: () => {},
      },
      // markerSelectIcon: {
      //   type: Object,
      //   default() {
      //     return Icon.elzSelected;
      //   },
      // },
      // marker: {
      //   type: Object,
      //   default() {
      //     return Icon.elz;
      //   },
      // },
    },
  };
</script>

<style lang="scss">
</style>