<template>
  <admin-fillall-layout>
    <component
      :is="mapImplement"
      v-if="captureThumbnail == null"
      :type-count="typeCount"
      :zoom.sync="zoom"
      :map-range.sync="mapRange"
      :no-fly-zone="noFlyZone"
      :edit-type="mapEditType"
      :selected-elz.sync="selectedPoint"
      @map-click="mapClick"
      @before-data-update="saveHistory"
      @operator="operator"
      @cancelElz="cancelElz"
    >
      <!-- info-window / overlay -->
      <elz-dialog
        v-if="selectedPoint != null && groupEditData.elz[selectedPoint]"
        :value="groupEditData.elz[selectedPoint]"
        :first.sync="elzFirst"
        @cancelElz="cancelElz"
        @close="selectedPoint = null"
        @delete="elzDelete"
        @before-data-update="saveHistory"
        @input="elzInput"
      />
    </component>
    <back-button @back-click="back" />
    <search-bar
      :location-name="groupEditData.name"
      @selectPlace="selectPlace"
    />
    <type-selector
      v-model="mapEditType"
      :items="['fence','elz']"
      @input="changeType"
    />
    <default-dialog
      :show-dialog="confirmShow"
      :next-button-text="$t('button.yes')"
      :center="true"
      :has-cancel="true"
      :show-close="false"
      footer-align="center"
      width="600px"
      @close="confirmShow = false"
      @next="saveToAPI"
    >
      <template slot="main">
        <div class="confirm-mesage-container">
          <p v-if="groupEditData.fence.length===0">
            {{ $t('page.setting.locationTab.noFence') }}
          </p>
          <p v-else-if="groupEditData.elz.length===0">
            {{ $t('page.setting.locationTab.noELZ') }}
          </p>
          <p v-else>
            {{ $t('page.setting.locationTab.saveConfirm') }}
          </p>
        </div>
      </template>
    </default-dialog>

    <default-dialog
      :show-dialog="beforeConfirmShow"
      :next-button-text="$t('button.ok')"
      :center="true"
      :has-cancel="false"
      :show-close="false"
      footer-align="center"
      width="600px"
      @close="beforeConfirmShow = false"
      @next="beforeConfirmShow=false"
    >
      <template slot="main">
        <div class="confirm-mesage-container">
          <p v-if="groupEditData.fence.length===0">
            {{ $t('page.setting.locationTab.noFence') }}
          </p>
          <p v-else-if="groupEditData.elz.length===0">
            {{ $t('page.setting.locationTab.noELZ') }}
          </p>
        </div>
      </template>
    </default-dialog>

    <save-button
      :text="$t('button.save')"
      :cancel-text="$t('button.cancel')"
      @cancel="cancel"
      @save="validateConfirm()"
    />
    <open-layers-capture-thumbnail
      v-if="captureThumbnail"
      :value="captureThumbnail"
      :promise="captureThumbnailPromise"
      :load-item="['fence']"
    />
  </admin-fillall-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';
import { GPSGetCurrentPosition } from '@/utils/map';
import BackButton from '@/components/Maps/BackButton.vue';
import SaveButton from '@/components/Maps/SaveButton.vue';
import DeleteDialog from '@/components/Maps/DeleteDialog.vue';
import SearchBar from '@/components/Maps/SearchBar.vue';
import TypeSelector from '@/components/Maps/TypeSelector.vue';
import DefaultDialog from '@/components/DefaultDialog.vue';
import ElzDialog from '@/components/Maps/ELZDialog.vue';

import NestApi from '@/services/api/domain/group/nest';
import NoFlyZoneAPI from '@/services/api/domain/noFlyZone';
import GroupApi from '@/services/api/domain/group';

import OpenLayersCaptureThumbnail from '@/components/OpenLayers/CaptureThumbnail.vue';

import OpenLayersImplement from './LocationMap-OpenLayers.vue';
import GoogleMapsImplement from './LocationMap-GoogleMaps.vue';

export default {
  name: 'SettingLocationMap',
  components: {
    BackButton,
    SaveButton,
    SearchBar,
    TypeSelector,
    ElzDialog,
    DefaultDialog,
    DeleteDialog,
    OpenLayersCaptureThumbnail,
  },
  data() {
    return {
      mapImplement: null,
      center: { latitude: 0, longitude: 0 },
      zoom: 2,
      typeCount: 0,
      mapRange: [],
      nest: [],
      mapEditType: 'fence',
      noFlyZone: [],
      zoneDialogDataIndex: null,
      history: [],
      historyPosition: 0,
      ignoreHistoryPosition: 0,
      confirmShow: false,
      beforeConfirmShow: false,
      selectedPoint: null,
      elzFirst: false,
      captureThumbnail: null,
      captureThumbnailPromise: null,
    };
  },
  computed: {
    ...mapGetters({
      domainCenter: 'user/location',
      group: 'user/group',
      mapEngine: 'user/mapEngine',
      groupEditData: 'group/getData',
      groupEditShow: 'group/shouldShow',
      groupEditMode: 'group/getMode',
    }),
  },
  watch: {
    async mapRange(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }

      try {
        let noFlyZone = await NoFlyZoneAPI.getList(now);
        noFlyZone = noFlyZone.data.no_fly_zones;

        this.noFlyZone = noFlyZone;
      } catch (error) {
        this.$showFail(error);
      }
    },
  },
  async mounted() {
    this.mapReset();
    if (this.groupEditData.latitude != 0 && this.groupEditData.longitude != 0) {
      this.mapPanTo(this.groupEditData);
      this.zoom = 17;
    } else {
      this.mapPanTo(this.domainCenter);
      this.zoom = 17;
    }

    try {
      if (this.groupEditMode == 'edit') {
        const { data } = await NestApi.getAll(this.groupEditData.id);
        this.nest = data.nests;
      }
    } catch (error) {
      this.$showFail(error);
    }

    switch (this.mapEngine) {
      case 'GoogleMaps':
        this.mapImplement = GoogleMapsImplement;
        break;
      case 'OpenLayers':
        this.mapImplement = OpenLayersImplement;
        break;
    }
  },
  methods: {
    mapClick(event) {
      if (event.source === 'elz') {
        this.elzOpen(event.sequence);
        return;
      }

      const data = CopyObject(this.groupEditData);
      switch (this.mapEditType) {
        case 'fence':
          if (event.source === 'fence') {
            break;
          }
          this.saveHistory();
          if (event.source === 'fence-middle') {
            data.fence.splice(event.sourceId + 1, 0, event.position);
          } else {
            data.fence.push(event.position);
          }
          this.setGroupEditData(data);
          break;
        case 'elz':
          if (event.source !== 'fence') {
            break;
          }
          this.saveHistory();
          const length = data.elz.push({
            name: 'ELZ Name',
            altitude: 0,
            ...event.position,
          });
          this.setGroupEditData(data);
          // wait data writing
          this.$nextTick(() => {
            this.elzFirst = true;
            this.elzOpen(length - 1);
          });
          break;
        default:
          break;
      }
    },
    async saveToAPI() {
      const group = CopyObject(this.groupEditData);
      const middle = group.fence.reduce((acc, val) => {
        acc.latitude += val.latitude;
        acc.longitude += val.longitude;
        return acc;
      }, { latitude: parseFloat(0), longitude: parseFloat(0) });
      middle.latitude /= group.fence.length;
      middle.longitude /= group.fence.length;
      group.latitude = middle.latitude;
      group.longitude = middle.longitude;
      this.setGroupEditData(group);
      const apiData = await this.exportGroupData();
      const loading = this.$loading({
        lock: true,
      });

      if (this.mapEngine === 'OpenLayers') {
        try {
          const thumbnailData = {
            ...apiData,
          };
          const image = await this.createThumbnail(thumbnailData);
          apiData.local_thumbnail = image;
        } catch (error) {
          this.$showFail('No Thumbnail created');
        }
      }

      try {
        let response;
        if (this.groupEditMode == 'add') {
          response = await GroupApi.create(apiData);
        } else {
          response = await GroupApi.update(group.id, apiData);
        }

        await this.updateGroupData();
        this.setGroupEditMode(null);
        this.$router.push({
          name: 'settingHome',
        });
      } catch (error) {
        this.$showFail(error);
      } finally {
        loading.close();
      }
    },
    async createThumbnail(data) {
      const capturePromise = new Promise((resolve, reject) => {
        this.captureThumbnailPromise = {
          resolve,
          reject,
        };
        this.captureThumbnail = {
          dataType: 'group',
          data,
        };
      });
      const result = await capturePromise;
      this.captureThumbnailPromise = null;
      this.captureThumbnail = null;
      return result;
    },
    cancel() {
      this.setGroupEditMode(null);
      this.back();
    },
    back() {
      // this.saveToStatus();

      this.$router.push({
        name: 'settingHome',
      });
    },
    operator(name) {
      switch (name) {
        case 'map-mode':
          this.typeCount += 1;
          break;
        case 'undo':
          if (this.historyPosition <= 0) {
            return;
          }
          this.historyPosition -= 1;
          this.setGroupEditData(this.history[this.historyPosition]);
          break;
        case 'clear':
          this.saveHistory();
          const data = CopyObject(this.groupEditData);
          data.fence = [];
          this.setGroupEditData(data);
          break;
        case 'gps':
          this.gps();
          break;
        default:
          this.$emit('operator', name);
      }
    },
    changeType(type) {
      // cleanup noFlyZone without any geolocation
      this.mapEditType = type;
      // if (this.mapEditZoneIndex != null) {
      //   let lastEditZone = this.map.noFlyZone[this.mapEditZoneIndex];
      //   if (lastEditZone.edit === 'create' && lastEditZone.polygons[0].length == 0) {
      //     this.map.noFlyZone.splice(this.mapEditZoneIndex, 1);
      //   }
      // }

      // if (this.noFlyZoneType.indexOf(type) >= 0) {
      //   let noFlyZone = new NoFlyZoneVM();
      //   noFlyZone.type = type;
      //   noFlyZone.edit = 'create';
      //   // this.saveHistory();
      //   this.mapEditZoneIndex = this.map.noFlyZone.push(noFlyZone) - 1;
      // } else {
      //   this.mapEditZoneIndex = null;
      // }
    },
    saveHistory() {
      if (this.history.length > this.historyPosition) {
        const toClean = parseInt(this.history.length) - parseInt(this.historyPosition);
        this.history.splice(this.historyPosition, toClean);
      }
      const index = this.history.push(CopyObject(this.groupEditData));
      this.historyPosition = parseInt(index);
    },
    async gps() {
      try {
        this.$showInfo(this.$t('notify.gettingGeolocation'));
        const position = await GPSGetCurrentPosition();
        const coord = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
          // this.$set(this, 'center', latLng);
        this.mapPanTo(coord);
        this.$set(this, 'zoom', 17);
      } catch (error) {
        if (error == null) {
          this.$showFail(this.$t('errorMsg.browserUnsupport'));
          return;
        }
        if (error.code == 1) {
          if (!window.isSecureContext) {
            this.$showFail(this.$t('errorMsg.requireSecureConnection'));
          } else {
            this.$showFail(this.$t('errorMsg.requireGeolocationPermission'));
          }
        } else {
          this.$showFail(this.$t('errorMsg.getLocationFailed'));
        }
      }
    },
    ...mapActions({
      setMapEngine: 'user/setMapEngine',
      setGroupEditData: 'group/setData',
      setGroupEditMode: 'group/setEditMode',
      exportGroupData: 'group/exportData',
      updateGroupData: 'user/getGroups',
      mapReset: 'map/reset',
      mapPanTo: 'map/panTo',
      mapFitBounds: 'map/fitBounds',
    }),
    selectPlace(place) {
      this.mapFitBounds(place.bounds);
    },
    deletePoint(point) {
      this.map.fence.splice(point, 1);
    },
    elzOpen(index) {
      if (this.history[this.historyPosition] == null) {
        this.history.push(CopyObject(this.groupEditData));
      }
      this.ignoreHistoryPosition = this.historyPosition;
      this.selectedPoint = index;
    },
    elzDelete() {
      this.saveHistory();
      const data = CopyObject(this.groupEditData);
      data.elz.splice(this.selectedPoint, 1);
      this.setGroupEditData(data);
    },
    elzIgnore() {
      this.historyPosition = this.ignoreHistoryPosition;
      this.setGroupEditData(this.history[this.ignoreHistoryPosition]);
    },
    elzInput(value) {
      const data = { ...this.groupEditData };
      data.elz[this.selectedPoint] = value;
      this.setGroupEditData(data);
    },
    validateConfirm() {
      if (this.groupEditData.fence.length === 0 || this.groupEditData.elz.length === 0) this.beforeConfirmShow = true;
      else this.confirmShow = true;
    },
    cancelElz() {
      if (this.elzFirst) {
        this.elzFirst = null;
        this.elzDelete();
      }
      this.selectedPoint = null;
    },
  },
  props: {
    flyAreaClick: {
      required: false,
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.confirm-mesage-container {
  text-align: center;
  margin-bottom: 80px;

  p {
    font-size: 16px;
  }

}
</style>
