<template>
  <div class="elz-editor">
    <h2>{{ $t('page.setting.locationTab.elz') }}</h2>
    <el-form :rules="rules">
      <el-form-item
        prop="elzName"
        :label="$t('page.setting.locationTab.name')"
        class="with_error_hint"
      >
        <el-input
          v-model="inputData.name"
          size="mini"
          @focus="$emit('before-data-update')"
          @blur="$emit('check-value')"
        />
      </el-form-item>
      <el-form-item :label="$t('page.device.dialog.latitude')">
        <el-input
          v-model="inputData.latitude"
          size="mini"
          @focus="$emit('before-data-update')"
          @blur="$emit('check-value')"
        />
      </el-form-item>
      <el-form-item :label="$t('page.device.dialog.longitude')">
        <el-input
          v-model="inputData.longitude"
          size="mini"
          @focus="$emit('before-data-update')"
          @blur="$emit('check-value')"
        />
      </el-form-item>
      <el-form-item :label="$t('map.altitude')">
        <el-input-number
          v-model="inputData.altitude"
          :controls="true"
          size="mini"
          @focus="$emit('before-data-update')"
          @blur="$emit('check-value')"
          @change="handleChange"
        />
        <Label class="with_error_hint">{{ `   ${unitHint}` }}</Label>
      </el-form-item>
      <el-form-item style="text-align: center">
        <el-button
          v-if="!first"
          size="mini"
          class=""
          type="warning"
          @click="deleteAction"
        >
          {{ $t('button.delete') }}
        </el-button>
        <el-button
          size="mini"
          class=""
          type="info"
          @click="$emit('cancelElz')"
        >
          {{ $t('button.cancel') }}
        </el-button>
        <el-button
          size="mini"
          class=""
          type="primary"
          @click="save"
        >
          {{ $t('button.done') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';
import { DisMeasure } from '@/utils/measure';

export default {
  name: 'ELZDialog',
  props: {
    first: {
      required: false,
      type: Boolean,
    },
    value: {
      required: false,
      type: Object,
    },
  },
  data() {
    const validateName = (rule, value, callback) => {
      const { name } = this.inputData;
      const nameEmpty = 'page.setting.locationTab.nameEmpty';

      if (name == null || name == '') {
        callback(new Error(this.$i18n.t(nameEmpty)));
        return;
      }
      callback();
    };
    return {
      inputData: {},
      rules: {
        elzName: [
          { validator: validateName, trigger: 'change' },
        ],
      },
      srcAltitude: 0,
    };
  },
  computed: {
    ...mapGetters({
      measurement: 'measurement',
    }),
    unitHint() {
      return DisMeasure.unit();
    },
  },
  watch: {
    measurement(newOne) {
      const elz = this.inputData;
      if (newOne == 'imperial') {
        elz.altitude = DisMeasure.display(elz.altitude);
      } else {
        elz.altitude = this.srcAltitude;
      }
    },
  },
  mounted() {
    this.inputData = this.initData();
  },
  methods: {
    initData() {
      const elz = CopyObject(this.value);
      this.srcAltitude = elz.altitude;
      elz.altitude = DisMeasure.display(elz.altitude);
      return elz;
    },
    handleChange() {
      this.srcAltitude = DisMeasure.real(this.inputData.altitude, 2);
    },
    close() {
      this.$emit('update:first', false);
      this.$emit('close');
      this.inputData = CopyObject(this.value);
    },
    deleteAction() {
      this.$emit('delete');
      this.close();
    },
    save() {
      const data = {
        ...this.inputData,
        latitude: parseFloat(this.inputData.latitude),
        longitude: parseFloat(this.inputData.longitude),
        altitude: this.srcAltitude,
      };
      this.$emit('input', data);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.elz-editor {
  width: 220px;

  .footer {
    text-align: center;
  }

  .el-input-number__decrease, .el-input-number__increase {
    background-color: #d2d2d2;
  }

  .el-input--mini {
    font-size: 12px;
  }

  .el-form-item.with_error_hint {
    margin-bottom : 16px;
  }

  label.with_error_hint{
    margin-left : 16px;
  }

}
</style>
