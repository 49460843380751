<template>
  <div>
    <default-google-map
      ref="map"
      :center="map.center"
      :zoom="zoom"
      :map-type-id="map.typeId"
      @center_changed="centerChange"
      @idle="onIdle"
      @tilesloaded="tilesLoaded"
      @click="mapClick"
      @bounds_changed="updateRange"
      @zoom_changed="
        event => {
          $emit('update:zoom', event);
        }
      "
    >
      <gmap-info-window
        :position="dialogPosition"
        :opened="dialogPosition != null"
        :options="{ disableAutoPan: true }"
        @closeclick="$emit('cancelElz')"
      >
        <div class="fms-infowindow-container">
          <slot />
        </div>
      </gmap-info-window>
      <template v-for="(waypoint, index) in map.elz">
        <gmap-marker
          :key="`elzWaypont${index}`"
          :position="waypoint"
          :icon="selectedElz == index ? markerSelectIcon : marker"
          :draggable="true"
          @dragstart="waypointDragstart"
          @drag="event => waypointDrag(event, index)"
          @dragend="event => waypointDragend(event, index)"
          @click="event => waypointClick(event, index)"
        />
      </template>
      <polygon-editor
        v-for="(zone, index) in map.noFlyZone"
        :key="`noFlyZone-${index}`"
        ref="zones"
        v-model="zone.polygons[0]"
        :readonly="true"
        :index="index"
        :polygon-component="formatNoFlyZoneTypeToComponent(zone.type)"
      />
      <polygon-editor
        ref="flyArea"
        :value="map.fence"
        :polygon-component="FlyArea"
        @polygon-click="event => mapClick(event, 'fence')"
        @before-input="saveHistory"
        @input="fenceInput"
        @delete-point="deletePoint"
      />

      <!-- <nest v-for="(position, index) in map.nest"
              :key="`nest-${index}`"
              :position="position" /> -->
    </default-google-map>
    <operator
      :menu-item="['map-mode', 'undo', 'clear', 'gps']"
      @click="o => $emit('operator', o)"
    />
    <zoom-button
      @zoomin="$emit('update:zoom', zoom + 1)"
      @zoomout="$emit('update:zoom', zoom - 1)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';
import {
  GeographicShorter, GeographicLonger, formatNoFlyZoneTypeToComponent, calculBounds,
} from '@/utils/map';

import Operator from '@/components/Maps/Operator.vue';
import ZoomButton from '@/components/Maps/ZoomButton.vue';
import DefaultGoogleMap from '@/components/GoogleMaps/DefaultGoogleMap';
import PolygonEditor from '@/components/GoogleMaps/PolygonEditor.vue';
import FlyArea from '@/components/GoogleMaps/FlyArea';
import { Icon } from '@/components/GoogleMaps/MarkerStyle';

export default {
  name: 'SettingLocationMapByGoogle',
  components: {
    DefaultGoogleMap,
    PolygonEditor,
    Operator,
    ZoomButton,
  },
  props: {
    noFlyZone: {
      type: Array,
    },
    mapRange: {
      type: Array,
    },
    center: {
      type: Object,
    },
    centerRead: {
      type: Object,
    },
    typeCount: {
      type: Number,
    },
    zoom: {
      type: Number,
    },
    editType: {
      type: String,
    },
    flyAreaClick: {
      required: false,
      type: Function,
      default: () => {},
    },
    markerSelectIcon: {
      type: Object,
      default() {
        return Icon.elzSelected;
      },
    },
    marker: {
      type: Object,
      default() {
        return Icon.elz;
      },
    },
    selectedElz: {},
  },
  data() {
    return {
      noFlyZoneType: ['altitude_limit', 'no_fly', 'obstacle'],
      map: {
        center: { lat: 0, lng: 0 },
        typeId: 'satellite',
        fence: [],
        noFlyZone: [],
        nest: [],
        elz: [],
      },
      lastMapRange: [],
      mapEditType: 'fence',
      // mapEditZoneIndex: null,
      zoneDialogDataIndex: null,
      history: [],
      historyPosition: 0,
      FlyArea,
      confirmShow: false,
      beforeConfirmShow: false,
      selectedPoint: null,
      tempBound: [],
      lastLoading: 0,
    };
  },
  computed: {
    dialogPosition() {
      if (this.selectedElz == null) {
        return null;
      }
      return GeographicShorter(this.groupEditData.elz[this.selectedElz]);
    },
    ...mapGetters({
      group: 'user/group',
      groupEditData: 'group/getData',
      groupEditShow: 'group/shouldShow',
      groupEditMode: 'group/getMode',
      mapCenter: 'map/centerGoogleMaps',
    }),
  },
  watch: {
    noFlyZone(data) {
      this.map.noFlyZone = data.map((zone) => {
        zone.polygons = zone.polygons.map((polygon) => polygon.map(GeographicShorter));
        return zone;
      });
    },
    nest(data) {
      this.map.nest = data.nests.map(GeographicShorter);
    },
    groupEditData() {
      this.loadFromVuex();
    },
    center(now) {
      // using two-way bind will hang map when you dragging
      // @center_changed="(event) => {this.$emit('update:center',event)}"

      this.$refs.map.panTo(GeographicShorter(now));
      this.$emit('update:centerRead', this.center);
    },
    typeCount(now) {
      const set = now % 2;
      switch (set) {
        case 0:
          this.map.typeId = 'satellite';
          break;
        case 1:
          this.map.typeId = 'roadmap';
          break;
        default:
          break;
      }
    },
    async lastMapRange(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }

      this.$emit('update:mapRange', now);
    },
  },
  async mounted() {
    this.loadFromVuex();
    this.map.center = this.mapCenter;
  },
  methods: {
    centerChange(center) {
      if (typeof center.lat === 'function') {
        center = {
          lat: center.lat(),
          lng: center.lng(),
        };
      }
      this.$emit('update:centerRead', GeographicLonger(center));
    },
    mapClick(event, source) {
      const clickEvent = {
        position: {
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
        },
        source,
      };
      this.$emit('map-click', clickEvent);
    },
    moveNest(event, index) {
      const nest = this.map.nest[index];
      if (nest.edit == null) {
        nest.edit = 'update';
      }
      nest.lat = event.latLng.lat();
      nest.lng = event.latLng.lng();
    },
    saveHistory() {
      this.$emit('before-data-update');
    },
    onIdle() {
      this.lastLoading += 1;
      const now = this.lastLoading;
      setTimeout(() => {
        if (now === this.lastLoading) {
          this.lastMapRange = this.tempBound;
          if (this.lastLoading > 9999) {
            this.lastLoading = 1;
          }
        }
      }, 1000);
    },
    updateRange(bounds) {
      if (bounds == null) {
        return;
      }
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      const round = 1e6;
      this.tempBound = [
        Math.floor(northEast.lat() * round) / round,
        Math.floor(northEast.lng() * round) / round,
        Math.ceil(southWest.lat() * round) / round,
        Math.ceil(southWest.lng() * round) / round,
      ];
    },
    formatNoFlyZoneTypeToComponent,
    ...mapActions({
      setGroupEditData: 'group/setData',
      setGroupEditMode: 'group/setEditMode',
      exportGroupData: 'group/exportData',
      updateGroupData: 'user/getGroups',
    }),
    waypointClick(event, index) {
      const clickEvent = {
        position: {
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
        },
        source: 'elz',
        sequence: index,
      };
      this.$emit('map-click', clickEvent);
    },
    waypointDragstart() {
      this.saveHistory();
    },
    waypointDragend(event, index) {
      // this.path[index].lat = event.latLng.lat();
      // this.path[index].lng = event.latLng.lng();
      const position = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
      // let x= event.latLng.lat();
      // let y= event.latLng.lng();
      //  let position = new google.maps.LatLng(
      //   x,
      //   y,
      // );
      const contains = this.checkPositionInZone(position, this.$refs.flyArea.$refs.polygon);
      if (contains) {
        // this.path[index].lat = event.latLng.lat();
        // this.path[index].lng = event.latLng.lng();
      } else {
        this.$emit('operator', 'undo');
        return;
      }

      const data = CopyObject(this.groupEditData);
      if (data.elz[index]) {
        data.elz[index].latitude = position.lat();
        data.elz[index].longitude = position.lng();
      }
      this.setGroupEditData(data);
    },
    waypointDrag(event, index) {
      this.$set(this.map.elz, index, {
        name: this.map.elz[index].name,
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    },
    deletePoint(point) {
      this.saveHistory();
      const data = CopyObject(this.groupEditData);
      data.fence.splice(point, 1);
      this.saveToVuex(data);
    },
    checkPositionInZone(position, polygon) {
      return google.maps.geometry.poly.containsLocation(position, polygon.$polygonObject);
    },
    validateConfirm() {
      if (this.map.fence.length === 0 || this.map.elz.length === 0) this.beforeConfirmShow = true;
      else this.confirmShow = true;
    },
    fenceInput(fence) {
      const data = {
        ...this.groupEditData,
      };
      data.fence = fence.map(GeographicLonger);

      this.setGroupEditData(data);
    },
    saveToVuex(data) {
      data.fence = data.fence.map(GeographicLonger);
      data.elz = data.elz.map(GeographicLonger);
      this.setGroupEditData(data);
    },
    loadFromVuex() {
      this.map.fence = this.groupEditData.fence.map(GeographicShorter);
      if (this.groupEditData.elz) {
        this.map.elz = this.groupEditData.elz.map(GeographicShorter);
      } else {
        this.map.elz = [];
      }
    },
    tilesLoaded() {
      const { fence } = this.map;
      if (this.tileLoaded == undefined && fence && fence.length > 0) {
        const boundcalculBounds = calculBounds(fence);
        this.$refs.map.fitBounds(boundcalculBounds);
      }
      this.tileLoaded = true;
    },
  },

};
</script>

<style lang="scss"></style>
